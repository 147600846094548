import styles from "./MainTitle.module.scss";

export default function MainTitle() {
  return (
    <div className={styles["desc-wrap"]}>
      <p>
        We handle the tech <br /> <i>You focus on results</i>
      </p>
    </div>
  );
}
