import screenshot_1 from "assets/images/cases/livius/screenshot_1.jpg";
import screenshot_2 from "assets/images/cases/livius/screenshot_2.jpg";
export const CASE_PAGES_LIVIUS = {
  name: "Livius",
  description:
    "Created a platform for elderly care with health monitoring features and improved interaction with medical staff – HealthTech",
  cost: "+50 000$",
  timeline: "7 months",
  teamSize: "8 people",
  techStack: "NodeJs, React, MongoDB, Redis, Mqtt broker, GCP",
  about:
    "Livius focuses on improving the quality of care and life for elderly people and people with disabilities in Australia. They offer technologies for quantitative, objective, and prompt tracking of critical indicators, which helps in providing quality care and preparing for future requirements. The company also provides a full cycle of services — from consultation and assessment to implementation and support.",
  website: "livius.com.au",
  images: [screenshot_1, screenshot_2],
  mainRequirements: `Development of a web application to work with devices for nursing homes. It was necessary to develop a complex infrastructure to ensure processing of large amounts of data from devices and uninterrupted system operation, as well as instant response to signals about critical situations on site.`,
  solution: `Our team developed a complex microservice architecture that ensures data collection from hundreds of devices and their analysis.

This allows for prompt response to emergency situations, for example, if a patient falls.

In addition, thanks to this system, we can accurately determine the patient's location: which room they are in, whether they are currently in a hospital bed or elsewhere.`,
  results:
    "After implementing the main functionality, we continued to add customizations to the functionality and optimize the cost and efficiency of the infrastructure.",
};
