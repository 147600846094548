import banner from "assets/images/cases/zabota/logo.png";
import screenshot_1 from "assets/images/cases/zabota/screenshot_1.png";
import screenshot_2 from "assets/images/cases/zabota/screenshot_2.png";
import screenshot_3 from "assets/images/cases/zabota/screenshot_3.jpg";

export const CASE_PAGES_ZABOTA = {
  logo: banner,
  name: "Zabota",
  description:
    "Developed a web application with integration of medical systems, improving patient interaction and automating the notification process – MedTech",
  cost: "$100,000+",
  timeline: "2022 - present",
  teamSize: "7 people",
  techStack: "React, C#, Rabbit, Redux toolkit, Typescript, Primereact, SasS",
  about:
    "ZABOTA 2.0 is a smart system for working with patient databases. The service helps bring patients back to the clinic through targeted mailings. With this application, the clinic increases revenue by working with its existing base and saves on advertising budget for attracting new patients.",
  website: "zabota.tech",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements:
    "At the time of contact, the company had its own desktop application. Our task was to develop a web interface with integration of the medical information system, reflecting three types of mailings for clients and the ability to view individual patient responses.",
  solution: `Based on the client's basic desktop application design, we developed our own administrative system, for which we implemented integrations in three directions - the client's main database (for system data integration), users' medical systems (for data collection), and an external messaging service (for centralized message dispatches). As a result of developing a branched microservice architecture, we achieved effective results both for users (in terms of functionality use and data relevance) and for the client (in terms of system stability, dispatch reliability, and scaling potential).`,
  results:
    "The team implemented a complex microservice architecture, ensuring data flow from hundreds of devices, as well as analysis of this data and telemetry, for timely response to alarm signals (emergency situations on site, such as a patient fall) and calibration of device data for more accurate determination of space and patients within it (for example, which room they are in, whether they are in a hospital bed, etc.). Today, there are >70 corporate clients in the database and the application sends >50,000 messages per day.",
};
