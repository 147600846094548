import VladImg from "assets/images/vlad.jpeg";
import AndreyImg from "assets/images/andrey.jpeg";
import styles from "./ConsultantsSection.module.css";

export default function ConsultantsSection() {
  const consultants = [
    {
      image: VladImg,
      name: "Vlad",
      title: "Founder of Cherrypick agency",
      description:
        "More than 10 years of experience in IT with background as .Net and React developer. Worked with many start-ups as CTO or IT consultant helping them to choose the right stack of technologies, recruit a team, found the right technical solution.",
    },
    {
      image: AndreyImg,
      name: "Andrey",
      title: "Chief Operating Officer",
      description:
        "Manager of all managers in Cherrypick agency. Dealing with all sort of challenges from rearranging the resources between the project to advising clients and designers on the best user-experience and business logic.",
    },
  ];

  return (
    <section className={styles.consultants}>
      <h2 className={styles.title}>Who are the consultants?</h2>
      <p className={styles.description}>
        Our consultants have experience building and scaling technology products. They are not
        salespeople or recruiters. They are here to help you solve problems.
      </p>
      <div className={styles.consultantList}>
        {consultants.map((consultant, index) => (
          <div key={index} className={styles.consultantCard}>
            <img src={consultant.image} alt={consultant.name} className={styles.consultantImage} />
            <h3 className={styles.consultantName}>{consultant.name}</h3>
            <p className={styles.consultantTitle}>{consultant.title}</p>
            <p className={styles.consultantDescription}>{consultant.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
