import Button from "../Button/Button";
import styles from "./CallToActionSection.module.css";

export default function CallToActionSection() {
  return (
    <section className={styles.cta}>
      <h2 className={styles.title}>Get the advice you need to succeed</h2>
      <p className={styles.description}>
        We're here to help you build and scale your startup. Book a free 30-minute consultation with
        an experienced tech leader today.
      </p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://calendly.com/vlad_k-cherrypick/30min "
      >
        <Button>Book a free 30-minute consultation</Button>
      </a>
    </section>
  );
}
