import resturyLogo from "assets/images/cases/restudy/logo.jpg";
import screenshot_1 from "assets/images/cases/restudy/screenshot_1.jpg";
import screenshot_2 from "assets/images/cases/restudy/screenshot_2.jpg";
import screenshot_3 from "assets/images/cases/restudy/screenshot_3.jpg";

export const CASE_PAGES_RESTUDY = {
  logo: resturyLogo,
  name: "ReStudy",
  description:
    "Developed an educational platform with a subscription model, adding a course and video lesson system, which contributed to the growth in the number of users – EdTech",
  cost: "+20 000$",
  timeline: "3 months",
  teamSize: "6 person",
  techStack: "Nodejs, react, postgreSQL",
  about:
    "ReStudy – an educational platform for online earning. There are 8 directions on the platform, with 83 lessons in each direction.",
  website: "restudy.io",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements:
    "ReStudy founders approached us. Their idea is to create an educational platform with a subscription model of content delivery. An important condition was that it was convenient for users to take courses on their phones. Plus, it was necessary to provide for flexible management of the site's sections - testimonials, services, news feed.",
  solution: `The project involved six specialists who developed and implemented two separate React-based services: one for the user interface and another for the administrative panel. Both were seamlessly integrated using a Node.js-based server architecture to ensure efficient performance and scalability.

To maximize SEO performance, we implemented server-side rendering (SSR) for the user platform, ensuring that the platform is optimized for search engines and delivers fast loading times for users.

Working from the client's provided design for the educational platform, we tailored the administrative panel to meet the specific needs of course management and user interaction.`,
  results:
    "It took 3 months from the first consultation to a working platform. Now there are >65 courses posted there and more than 3000 registered users.\n\nThe platform now allows users to:\nManage both users and administrators;\nHandle course content, including adding video lessons and homework files;\nCustomize administrative permissions for speakers, enabling them to create courses, respond to user inquiries, publish news, manage earnings, list services with pricing, and accept service requests;\nAssign moderators to courses, giving them the ability to edit content and answer student questions.\n\nAdditionally, we integrated multiple payment gateways, along with a referral link system and payment tracking functionality to streamline revenue management.\n\nThis solution provided the client with a flexible, scalable platform that supports both course creators and users, driving the platform's overall growth while ensuring strong SEO performance.",
};
