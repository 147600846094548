import banner from "assets/images/cases/talentculture/logo.png";
import screenshot_1 from "assets/images/cases/talentculture/screenshot_1.png";
import screenshot_2 from "assets/images/cases/talentculture/screenshot_2.png";
import screenshot_3 from "assets/images/cases/talentculture/screenshot_3.png";

export const CASE_PAGES_TALENT_CULTURE = {
  logo: banner,
  name: "Talent Culture",
  description:
    "Разработали HR-приложение для 500 000+ участников, оптимизировав архитектуру для управления персоналом – HRTech",
  cost: "+50 000$",
  timeline: "6 месяцев",
  teamSize: "4 человека",
  techStack: "C#, React, MsSQL, Azure ML",
  about:
    "Talent Culture – компания, которая создала технологический продукт для управления персоналом. Идея Talent Culture – создать сервис для компаний, чтобы они могли измерять и анализировать вовлеченность сотрудников.",
  website: "https://talentculture.com/",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements: `Главная цель клиента прийти к полному циклу работы с персоналом: подбор, адаптация и организация рабочего процесса.

Поэтому, нам нужно было разработать многофункциональное приложение, которое позволяло бы:
• оценивать вовлеченность персонала;
• анализировать удовлетворенность сотрудников условиями труда;
• систематизировать полученную информацию для принятия решений;
• отслеживать результаты каждого сотрудника и команды в целом, чтобы выявить определенные тенденции.

Мы должны были с нуля реализовать функционал для анализа пользовательских данных и настроить все связанные с этим процессы. Так как клиент работает в высококонкурентной среде, нам нужно было внедрить нестандартные, но эффективные решения, чтобы привлечь внимание целевой аудитории.`,
  solution: `Мы разработали проект от А до Я: от создания макетов и внедрения бизнес-логики до развертывания программной системы. Чтобы обеспечить качественный анализ пользовательских данных, мы решили интегрировать проект с IBM. Это повысило точность результатов исследований и обеспечило стабильность работы приложения.

Компания внедрила инновационное решение, интегрировав свои услуги с IBM для оптимизации процесса найма. Система теперь использует чат-бота Debra, который анализирует взаимодействие с кандидатами и их резюме. Такой подход позволяет выявлять личностные характеристики и эмоциональное состояние соискателей. Работодатели получают возможность оценивать, насколько кандидаты соответствуют их требованиям. При этом они могут использовать стандартные настройки или адаптировать их под свои нужды, сотрудничая с психологами. Это помогает улучшить качество найма и повысить удовлетворенность как работодателей, так и кандидатов.`,
  results: `Клиент получил сервис, в котором уже 500 000+ участников и их количество растет с каждым днем. Приложение успешно справляется с такими объемами контента. А сочетание услуг IBM и собственных разработок сделало приложение гибким и легко масштабируемым.`,
};
