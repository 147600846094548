import HeroSection from "./HeroSection/HeroSection";
import ServicesSection from "./ServicesSection/ServicesSection";
import ConsultantsSection from "./ConsultantsSection/ConsultantsSection";
import CallToActionSection from "./CallToActionSection/CallToActionSection";
import AboutSection from "./AboutSection/AboutSection";
import Footer from "./Footer/Footer";
import styles from "./Consult.module.css";
import Header from "components/Header/Header";

export default function Consult() {
  return (
    <div className={styles.consultPage}>
      <div className={styles.pageContent}>
        <Header />
        <main className={styles.mainContent}>
          <div className={styles.sections}>
            <HeroSection />
            <ServicesSection />
            <ConsultantsSection />
            <CallToActionSection />
            <AboutSection />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
