import { memo, useState } from "react";
import styles from "./ContactUs.module.scss";
import styled from "../Header/Header.module.scss";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import icon from "../../assets/images/contact-us-success-form-icon.svg";
import { useNavigate } from "react-router-dom";
import { Logo } from "assets/images/cherrypickLogo";

const ContactUsLayout = ({ sendFeedback, isSubmit, handleSubmit }) => {
  const [info, setInfo] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  let validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  return (
    <div className={styles["main-layout"]}>
      <div className={styles["main-layout-content"]}>
        <div className={styles["header-layout-top-container"]}>
          <div className={styled["logo-wrapper"]} onClick={() => navigate("/recruit")}>
            <Logo />
          </div>
          <span className={styles["header-layout-bottom-border"]}>
            ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
          </span>
        </div>
        <div className={styles["contact-us-layout"]}>
          <div className={classNames(styles["contact-us-column"], styles["bottom-column"])}>
            <div className={styles["contact-us-title-wrapper"]}>
              <span className={styles["contact-us-title"]}>Get in touch</span>
            </div>
            <span className={styles["contact-us-subtitle"]}>Contact us</span>
            <div className={styles["contact-us-addres-desktop"]}>
              <p className={styles["contact-us-adres"]}>
                507 Polk St San Francisco, CA 94102 (415) 980-8030
              </p>
            </div>
            <div className={styles["contact-us-mobile-addres"]}>
              <p className={styles["contact-us-adres"]}>507 Polk St</p>
              <p className={styles["contact-us-adres"]}>San Francisco, CA 94102</p>
              <p className={styles["contact-us-adres"]}>(415) 980-8030</p>
            </div>
            <a href="mailto:vlad_k@cherrypick.agency">
              <p className={styles["contact-us-text-info"]}>vlad_k@cherrypick.agency</p>
            </a>
            {/* <p className={styles["contact-us-text-info"]}>t.me/cherrypick</p> */}
            {/* <span className={styles["contact-us-subtitle"]}>Phone</span> */}
            {/* <p className={styles["contact-us-text-info"]}>(987) 654-33-22</p> */}
          </div>
          <div className={classNames(styles["contact-us-column"], styles["top-column"])}>
            <div
              className={classNames(
                styles["contact-us-success-form-wrapper"],
                isSubmit && styles["wrapper-hide"]
              )}
            >
              <p className={styles["contact-us-input-title"]}>
                Comment <i>(optional)</i>
              </p>
              <InputTextarea
                rows={5}
                cols={30}
                value={info}
                onChange={(e) => setInfo(e.target.value)}
                autoResize
                className={styles["contact-us-text-area"]}
              />
              <p className={styles["contact-us-input-title"]}>Email</p>
              <InputText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles["contact-us-email-input"]}
                placeholder="your@email.com"
              />
              {/* <div className={styles["contact-us-captcha-wrapper"]}>
                <ReCAPTCHA sitekey="Your client site key" onChange={onChange} />
              </div> */}
              <div className={styles["contact-us-submit-btn-wrapper"]}>
                <button
                  className={classNames(styles["submit-btn"], styles["ok-btn"])}
                  onClick={() => sendFeedback({ email, comment: info })}
                  disabled={!info || !email || !validEmail}
                >
                  Submit
                </button>
              </div>
            </div>
            <div
              className={classNames(
                styles["contact-us-success-submit-wrapper"],
                !isSubmit && styles["wrapper-hide"]
              )}
            >
              <span>Thank you for your request!</span>
              <p>We will definitely reply to you soon</p>
              <button className={styles["submit-btn"]} onClick={() => handleSubmit(false)}>
                Ok
              </button>
              <img src={icon} alt="handle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactUsLayout);
