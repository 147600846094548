import { PROJECTS } from "constants/projects";
import { memo } from "react";
import { Link } from "react-router-dom";
import styles from "./Projects.module.scss";

const ProjectsLayout = () => {
  return (
    <div className={styles["projects-layout"]}>
      <div className={styles["projects-layout-title"]}>
        <p>Our projects</p>
      </div>
      <div className={styles["projects-layout-list"]}>
        {PROJECTS.map((project, index) => (
          <div className={styles["projects-layout-item"]} key={index}>
            <a target="_blank" href={project.link}>
              <h1>{project.title}</h1>
            </a>
            <span>{project.desc}</span>
            <p>{project.tags}</p>
          </div>
        ))}
      </div>
      <Link to="/cases" reloadDocument className={styles["projects-layout-footer"]}>
        <button>All Cases</button>
      </Link>
    </div>
  );
};

export default memo(ProjectsLayout);
