import { memo } from "react";
import styles from "./Footer.module.scss";

const FooterLayout = () => {
  return (
    <div className={styles["footer-layout"]}>
      <span className={styles["footer-contact-us"]}>Contact us</span>
      <div className={styles["footer-addres-wrapper"]}>
        <p className={styles["addres"]}>507 Polk St</p>
        <p className={styles["addres"]}>San Francisco, CA 94102</p>
        {/*<p className={styles["addres"]}>(415) 980-8030</p>*/}
      </div>
      <a href="mailto:vlad_k@cherrypick.agency">
        <p className={styles["connection-text"]}>vlad_k@cherrypick.agency</p>
      </a>
      {/* <p className={styles["connection-text"]}>t.me/cherrypick</p> */}
      {/* <span className={classNames(styles["footer-contact-us"], styles["check-jobs"])}>
        Check our job posts
      </span> */}
      {/* <p className={styles["connection-text"]}>t.me/hrcherrypick</p> */}
    </div>
  );
};

export default memo(FooterLayout);
