import classNames from "classnames";
import styles from "./Faces.module.scss";
import dashImg from "../../assets/images/dash.svg";

export default function Faces() {
  return (
    <div>
      <div className={styles["new-prj-faces"]}>
        <div className={classNames(styles["new-prj-faces-col"], styles["left-col"])}>
          <img
            src={"./assets/telegram-cloud-photo-size-2-5454326682161896927-y.jpg"}
            alt="vlad-frame"
          />
          <span>Vlad</span>
          <div className={styles["new-prj-faces-col-group-left"]}>
            <img src={dashImg} alt="dash" />
            <p>Founder</p>
          </div>
        </div>
        <div className={classNames(styles["new-prj-faces-col"], styles["right-col"])}>
          <img
            src={"./assets/telegram-cloud-photo-size-2-5463053956923121930-y.jpg"}
            alt="andrey-frame"
          />
          <span>Andrey</span>
          <div className={styles["new-prj-faces-col-group-right"]}>
            <img src={dashImg} alt="dash" />
            <p>Chief Operating Officer</p>
          </div>
        </div>
        <div className={classNames(styles["new-prj-faces-col"], styles["right-col"])}>
          <img
            src={"./assets/telegram-cloud-photo-size-2-5463053956923121931-y.jpg"}
            alt="andrey-frame"
          />
          <span>Olesya</span>
          <div className={styles["new-prj-faces-col-group-right"]}>
            <img src={dashImg} alt="dash" />
            <p>Chief Human Resource Manager</p>
          </div>
        </div>
      </div>
    </div>
  );
}
