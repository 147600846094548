import styles from "./AboutSection.module.css";

export default function AboutSection() {
  return (
    <section className={styles.about}>
      <h2 className={styles.title}>About Us</h2>
      <p className={styles.description}>
        We are Cherrypick Agency, specialists in building mobile and web applications. Our goal is
        to help startups with their technology needs and provide them with the best solutions.
      </p>
      <div className={styles.mission}>
        <h3 className={styles.missionTitle}>Our Mission</h3>
        <p className={styles.missionDescription}>
          To be the trusted technology partner for startups and help them succeed in their journey.
        </p>
      </div>
    </section>
  );
}
