import React from "react";
import TelegramIcon from "assets/images/telegram.svg";
import InstagramIcon from "assets/images/instagram.svg";
import LinkedInIcon from "assets/images/linkedin.svg";

import styles from "./Footer.module.css";

const socialIcons = [
  { src: TelegramIcon, alt: "Telegram", href: "https://t.me/cherrypickagency" },
  { src: InstagramIcon, alt: "Instagram", href: "#" },
  {
    src: LinkedInIcon,
    alt: "LinkedIn",
    href: "https://www.linkedin.com/company/cherry-pick-agency",
  },
];

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contact}>
        <h3 className={styles.title}>Contact Us</h3>
        <a href="mailto:vlad_k@cherrypick.agency" className={styles.email}>
          vlad_k@cherrypick.agency
        </a>
      </div>

      <div className={styles.column}>
        <h3 className={styles.title}>Follow us</h3>
        <div className={styles.socialContainer}>
          {socialIcons.map((icon, index) => (
            <a
              key={index}
              href={icon.href}
              className={styles.socialIcon}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon.src} alt={icon.alt} className={styles.icon} loading="lazy" />
            </a>
          ))}
        </div>
      </div>

      <div className={styles.reviews}>
        <h3 className={styles.title}>Reviews on Clutch</h3>
        <div className={styles.clutchWidget}>
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="14"
            data-height="50"
            data-nofollow="true"
            data-expandifr="true"
            data-clutchcompany-id="2064479"
          ></div>
        </div>
      </div>

      <div className={styles.copyright}>
        © {new Date().getFullYear()} Cherrypick Agency. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
