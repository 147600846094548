import service1Img from "assets/images/service-1.png";
import service2Img from "assets/images/service-2.png";
import service3Img from "assets/images/service-3.png";

import Button from "../Button/Button";
import styles from "./ServicesSection.module.css";

export default function ServicesSection() {
  const services = [
    {
      icon: service1Img,
      title: "Gather your team",
      description: "Find the right people to build your product",
    },
    {
      icon: service2Img,
      title: "Guide your app development",
      description: "Get advice on your app's architecture, tech stack, and more",
    },
    {
      icon: service3Img,
      title: "Audit your current stage",
      description: "Review the codebase, infrastructure, and more of a live product",
    },
  ];

  return (
    <section className={styles.services}>
      <h2 className={styles.title}>How can we help?</h2>
      <p className={styles.subtitle}>We offer consultations in three areas:</p>
      <div className={styles.serviceList}>
        {services.map((service, index) => (
          <div key={index} className={styles.serviceItem}>
            <img src={service.icon} alt={service.title} className={styles.icon} />
            <h3 className={styles.serviceTitle}>{service.title}</h3>
            <p className={styles.serviceDescription}>{service.description}</p>
          </div>
        ))}
      </div>
      <a
        className={styles.startConsultationLink}
        target="_blank"
        rel="noopener noreferrer"
        href="https://calendly.com/vlad_k-cherrypick/30min "
      >
        <Button>Start a consultation</Button>
      </a>
    </section>
  );
}
