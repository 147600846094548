import ClientFeedbaks from "components/ClientFeedbaks/ClientFeedbacks";
import Faces from "components/Faces/Faces";
import Footer from "components/Footer/Footer";
import HireWithUs from "components/HireWithUs/HireWithUs";
import MainTitle from "components/MainTitle/MainTitle";
import Menu from "components/Menu/Menu";
import NewProject from "components/NewProject/NewProject";
import Projects from "components/Projects/Projects";
import { FEEDBACKS_LIST } from "constants/feedbacks-list";
import React, { memo } from "react";
import { ScrollRestoration } from "react-router-dom";
import Header from "../../Header/Header";
import styles from "./MainContentLayout.module.scss";

const MainContentLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles["main-layout"]}>
      <div className={styles["main-layout-content"]}>
        <Header />
        <MainTitle />
        <NewProject />
        <Menu />
        <Projects />
        <Faces />
        <ClientFeedbaks feedbaksList={FEEDBACKS_LIST} />
        <HireWithUs />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default memo(MainContentLayout);
