import { CASE_PAGES_ENGAGEROCKET } from "./engagerocket";
import { CASE_PAGES_ZABOTA } from "./zabota";
import { CASE_PAGES_FOODLABS } from "./foodlabs";
import { CASE_PAGES_LIVIUS } from "./livius";
import { CASE_PAGES_RESTUDY } from "./restudy";
import { CASE_PAGES_ALBIONE } from "./albione";
import { CASE_PAGES_AUTLET } from "./autletmarket";
import { CASE_PAGES_SESHME } from "./seshme";
import { CASE_PAGES_JANCE } from "./jance";
import { CASE_PAGES_TALENT_CULTURE } from "./talent-culture";

export const CASE_PAGES = {
  engagerocket: CASE_PAGES_ENGAGEROCKET,
  zabota: CASE_PAGES_ZABOTA,
  foodlabs: CASE_PAGES_FOODLABS,
  livius: CASE_PAGES_LIVIUS,
  restudy: CASE_PAGES_RESTUDY,
  albione: CASE_PAGES_ALBIONE,
  autletmarket: CASE_PAGES_AUTLET,
  seshme: CASE_PAGES_SESHME,
  jance: CASE_PAGES_JANCE,
  talentculture: CASE_PAGES_TALENT_CULTURE,
};
