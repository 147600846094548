import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import NewProject from "components/NewProject/NewProject";
import { FLATTER_CASES } from "constants/cases";
import { memo } from "react";
import { Link } from "react-router-dom";
import styles from "./FlatterCases.module.scss";

const FlatterCasesLayout = () => {
  return (
    <div className={styles["main-layout"]}>
      <div className={styles["main-layout-content"]}>
        <Header />
        <div className={styles["projects-layout-wrapper"]}>
          <div className={styles["projects-layout-title"]}>
            <p>Flutter Cases</p>
          </div>

          <div className={styles["projects-layout"]}>
            <div className={styles["projects-layout-list"]}>
              {FLATTER_CASES.map((project, index) => (
                <div className={styles["projects-layout-item"]} key={index}>
                  <Link to={`/cases/${project.name}`}>
                    <img
                      src={project.image}
                      alt={project.direction}
                      className={styles["project-image"]}
                    />
                  </Link>
                  <h2>{project.direction}</h2>

                  <span>{project.description}</span>
                  <p>{project.tags}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <NewProject />
        <Footer />
      </div>
    </div>
  );
};

export default memo(FlatterCasesLayout);
