import engagerocket from "assets/images/cases/engagerocket/banner.png";
import zabota from "assets/images/cases/zabota/banner.png";
import foodlabs from "assets/images/cases/foodlabs/banner.png";
import livius from "assets/images/cases/livius/banner.png";
import albione from "assets/images/cases/albione/banner.png";
import restudy from "assets/images/cases/restudy/banner.png";
import autletmarket from "assets/images/cases/autletmarket/banner.png";
import seshme from "assets/images/cases/seshme/banner.png";
import jance from "assets/images/cases/jance/banner.png";

export const CASES = [
  {
    name: "engagerocket",
    direction: "HRTech",
    description: "How we rewrote an HR platform app now used by 350,000+ people",
    tags: "#hr #backend #frontend #react #nodejs #docker #kubernetes",
    image: engagerocket,
  },
  {
    name: "zabota",
    direction: "MedTech",
    description:
      "How we created a web application with MIS integration and mailings to patients of private clinics",
    tags: "#healthcare #backend #frontend #react #nodejs #docker",
    image: zabota,
  },
  {
    name: "foodlabs",
    direction: "HealthTech",
    description: "How we developed an app and recommendation algorithm for food selection",
    tags: "#healthcare #ml #backend #frontend #react #nodejs",
    image: foodlabs,
  },
  {
    name: "livius",
    direction: "HealthTech",
    description: "How we built a platform for elderly care",
    tags: "#healthcare #backend #frontend #react #nodejs #docker",
    image: livius,
  },
  {
    name: "albione",
    direction: "E-com",
    description: "How we finalized a mobile application for an online clothing store",
    tags: "#ecommerce #mobile #flutter #backend #nodejs",
    image: albione,
  },
  {
    name: "restudy",
    direction: "EdTech",
    description: "How we created an educational platform with a subscription model",
    tags: "#edtech #backend #frontend #react #nodejs #docker",
    image: restudy,
  },
  {
    name: "autletmarket",
    direction: "E-com",
    description: "Finalized mobile application for comfortable and profitable clothes shopping",
    tags: "#ecommerce #mobile #flutter #backend #nodejs",
    image: autletmarket,
  },
  {
    name: "seshme",
    direction: "HealthTech",
    description:
      "How we updated the cross-platform fitness app, added new features and helped increase the project's client base by 40%",
    tags: "#sport #mobile #flutter #backend #nodejs #docker",
    image: seshme,
  },
  {
    name: "jance",
    direction: "EdTech",
    description:
      "How 0 and I developed a Flutter-based app for an online dance school with the ability to record courses right inside the platform",
    tags: "#edtech #mobile #flutter #backend #nodejs #docker",
    image: jance,
  },
];

export const FLATTER_CASES = [
  {
    name: "foodlabs",
    direction: "HealthTech",
    description: "How we developed an app and recommendation algorithm for food selection",
    tags: "#healthcare #ml #backend #frontend #react #nodejs",
    image: foodlabs,
  },
  {
    name: "albione",
    direction: "E-com",
    description: "How we finalized a mobile application for an online clothing store",
    tags: "#ecommerce #mobile #flutter #backend #nodejs",
    image: albione,
  },
  {
    name: "restudy",
    direction: "EdTech",
    description: "How we created an educational platform with a subscription model",
    tags: "#edtech #backend #frontend #react #nodejs #docker",
    image: restudy,
  },
  {
    name: "autletmarket",
    direction: "E-com",
    description: "Finalized mobile application for comfortable and profitable clothes shopping",
    tags: "#ecommerce #mobile #flutter #backend #nodejs",
    image: autletmarket,
  },
  {
    name: "seshme",
    direction: "HealthTech",
    description:
      "How we updated the cross-platform fitness app, added new features and helped increase the project's client base by 40%",
    tags: "#sport #mobile #flutter #backend #nodejs #docker",
    image: seshme,
  },
  {
    name: "jance",
    direction: "EdTech",
    description:
      "How 0 and I developed a Flutter-based app for an online dance school with the ability to record courses right inside the platform",
    tags: "#edtech #mobile #flutter #backend #nodejs #docker",
    image: jance,
  },
];
