import screenshot_1 from "assets/images/cases/foodlabs/screenshot_1.png";
import screenshot_2 from "assets/images/cases/foodlabs/screenshot_2.png";
import screenshot_3 from "assets/images/cases/foodlabs/screenshot_3.png";

export const CASE_PAGES_FOODLABS = {
  name: "Foodlabs",
  description:
    "Implemented an application with a recommendation algorithm for personalized food selection, significantly increasing user engagement – HealthTech",
  cost: "+50 000 $",
  timeline: "6 months",
  teamSize: "12 persons",
  techStack: "Flutter, Nodejs, Python, React, PostgreSQL",
  about:
    "Foodlabs is a mobile app that develops healthy eating plans based on health screening results. By integrating health data and the expertise of nutritionists, it offers personalized nutritional recommendations that promote optimal well-being and meet individual health needs.",

  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements:
    "The primary request from the client was to develop a mobile application that could offer personalized food recommendations based on users' medical parameters, dietary preferences, and health goals. The goal was to create an intuitive and user-friendly platform that would provide tailored food suggestions to help users maintain a healthy lifestyle.",
  solution: `To address the client's need for personalized food recommendations, we developed mobile applications for both iOS and Android using Flutter, ensuring a consistent and seamless experience across platforms. Flutter allowed us to build a high-performance app with a single codebase, reducing development time and simplifying future updates.

We collaborated closely with the company's methodologists to design and implement a recommendation algorithm. This algorithm analyzes user data to offer personalized food selections from the company's partner network, ensuring that recommendations align with users' preferences and dietary needs.`,
  results:
    "The app now provides the following key functionalities:\n\nPersonalized product recommendations: Based on user preferences, behavior, and analytics, the algorithm suggests products from the company's partners.\n\nCross-platform consistency: Both iOS and Android apps deliver the same high-quality user experience, optimized for performance and responsiveness.\n\nUser engagement tools: Integrated features like user feedback on recommendations, allowing the algorithm to continuously improve and refine its suggestions.\n\nThis solution resulted in a significant increase in user engagement by delivering relevant, data-driven recommendations that keep users connected to the platform and its partner services.",
};
