import screenshot_1 from "assets/images/cases/seshme/screenshot_1.png";
import screenshot_2 from "assets/images/cases/seshme/screenshot_2.png";
import screenshot_3 from "assets/images/cases/seshme/screenshot_3.png";

export const CASE_PAGES_SESHME = {
  name: "SeshMe",
  description:
    "Updated a cross-platform fitness app by introducing personalized training features, which helped attract more users – HealthTech",
  cost: "30 000$+",
  timeline: "2017-2021",
  teamSize: "6 person",
  techStack: "Nodejs, C#, React Native, React, MsSQL",
  about:
    "SeshMe is an Australian fitness cross platform mobile app. On the platform, people can take online courses, participate in group workouts and connect with friends and like-minded people. There are also features for coaches to create fitness communities and create customized workout plans.",
  website: "seshme.com",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements: `
We needed to:
- Finish the application that was unfinished by previous contractors;
- improve business logic and data management;
- develop and implement business functions: create group and personal training sessions on a map, implement a recommendation algorithm of sessions for users and trainers, add recurring payments, etc.;
- ensure smooth operation of the application;
- understand the code base;
- finalize the backend, mobile app and admin panel;
- implement several key features of the application.

The client had a negative experience with contractors, so, it was important for them to be involved in the development as well as to be regularly updated on the progress of the app development.
  `,
  solution: `We have updated all program components to maintain overall performance and security. We added the ability to export/import data, analyze information, collect and process statistics and save it in the database.
Also, we conducted a joint UX study taking into account any possible user scenarios. Implemented functionality for coaches: scheduling, client communication, post-session payments, activity rewards, and more. We improved features for participants: session customization, training registration, inviting friends, and trainer rating.
Finally, we customized CI /CD to consolidate development, testing and deployment capabilities and set up automatic release of the app to the Appstore and Google Market.`,
  results: `We introduced a cross-platform mobile app ready to launch in the Australian market.
A couple of months after launch, the client reported to us that:
- The project's customer base had increased by over 40%, mainly due to the friend invitation feature;
- Both beginners and experienced trainers from different industries have joined the app: e.g. yoga, boxing and others;
- According to business users, online coaching has helped them achieve 80% client retention;
The project has gained popularity in Australia, Europe and the US, and we are confident of its growth in other countries in the future.`,
};
