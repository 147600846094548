import { MENU } from "constants/menu";
import { memo, useCallback, useState } from "react";
import styles from "./Menu.module.scss";
import classNames from "classnames";
import { FrameworkImagesAnimation } from "components/common/FrameworkImagesAnimation/FrameworkImagesAnimation";

const MenuLayout = () => {
  const [activeTab, setActiveTab] = useState<string>("Flutter");

  const renderAnimation = useCallback(() => {
    return <FrameworkImagesAnimation activeTab={activeTab} />;
  }, [activeTab]);

  return (
    <div className={styles["menu-layout"]}>
      <div className={styles["header-text"]}>
        <i>/*</i>your development team is ready to get started<i>*/</i>
      </div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["menu-list-container"]}>
          <div className={styles["menu-list"]}>
            {MENU.map((el) => (
              <p
                key={el.name}
                className={classNames(
                  styles["menu-item"],
                  (activeTab === el.name && styles["active-item"]) || ""
                )}
                onClick={() => setActiveTab(el.name)}
              >
                {el.name}
              </p>
            ))}
          </div>
        </div>
        <div className={styles["animation-container"]}>{renderAnimation()}</div>
      </div>
    </div>
  );
};

export default memo(MenuLayout);
