import Header from "components/Header/Header";
import NewProject from "components/NewProject/NewProject";
import Footer from "components/Footer/Footer";
import styles from "./Case.module.scss";
import { Link, useParams } from "react-router-dom";
import { CASE_PAGES } from "constants/case-pages/case-pages";

const Case = () => {
  const { name } = useParams<{ name: string }>();
  const project = name ? CASE_PAGES[name] : null;

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className={styles["main-layout"]}>
      <div className={styles["main-layout-content"]}>
        <Header />
        <div className={styles.projectDetails}>
          <div className={styles.projectHeader}>
            {project.logo && (
              <img src={project.logo} alt={project.name} className={styles.projectLogo} />
            )}
            <h1 className={styles.projectName}>{project.name}</h1>
          </div>

          <p className={styles.projectDescription}>{project.description}</p>

          <div className={styles.projectStats}>
            <div className={styles.statCard}>
              <img src="https://static.tildacdn.com/tild6363-3337-4161-a139-666131633761/vector.svg" />
              <h3>Project amount</h3>
              <p>{project.cost}</p>
            </div>

            <div className={styles.statCard}>
              <img src="https://static.tildacdn.com/tild3839-6637-4630-b835-653633373762/vector.svg" />
              <h3>Timeline</h3>
              <p>{project.timeline}</p>
            </div>

            <div className={styles.statCard}>
              <img src="https://static.tildacdn.com/tild3733-3062-4161-a239-653666613764/vector.svg" />
              <h3>Team</h3>
              <p>{project.teamSize}</p>
            </div>

            <div className={styles.statCard}>
              <img src="https://static.tildacdn.com/tild3563-6439-4633-a239-326335663739/vector.svg" />
              <h3>Stack</h3>
              <p>{project.techStack}</p>
            </div>
          </div>

          <div className={styles.projectContent}>
            <div className={styles.textSection}>
              <h2>About the project</h2>
              <p>{project.about}</p>
              {project.website && (
                <span>
                  Website:{" "}
                  <a
                    href={`https://${project.website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.projectLink}
                  >
                    {project.website}
                  </a>
                </span>
              )}
              <div className={styles.clutchLinkWrapper}>
                <a href="" className={styles.clutchLink}>
                  Review on Clutch
                </a>
              </div>
            </div>

            <div className={styles.screenshotsSection}>
              <img
                src={project.images[0]}
                alt={`Project screenshot 1`}
                className={styles.projectImage}
              />
            </div>
          </div>

          <div className={styles.projectContent}>
            <div className={styles.screenshotsSection}>
              <img
                src={project.images[1]}
                alt={`Project screenshot 2`}
                className={styles.projectImage}
              />
            </div>
            <div className={styles.textSection}>
              <h2>Main request</h2>
              <p>{project.mainRequirements}</p>
            </div>
          </div>

          <div className={styles.projectContent}>
            <div className={styles.textSection}>
              <h2>Problem solving</h2>
              <p>{project.solution}</p>
            </div>
            <div className={styles.screenshotsSection}>
              {project.images[2] && (
                <img
                  src={project.images[2]}
                  alt={`Project screenshot 3`}
                  className={styles.projectImage}
                />
              )}
            </div>
          </div>
          <div className={styles.textSection}>
            <h2>Result</h2>
            <p>{project.results}</p>
          </div>
        </div>
        <Link className={styles.backButton} to="/cases">
          <button>Back to Cases</button>
        </Link>
        <NewProject />
        <Footer />
      </div>
    </div>
  );
};

export default Case;
