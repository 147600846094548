import screenshot_1 from "assets/images/cases/jance/screenshot_1.png";
import screenshot_2 from "assets/images/cases/jance/screenshot_2.png";
import screenshot_3 from "assets/images/cases/jance/screenshot_3.png";

export const CASE_PAGES_JANCE = {
  name: "Jance",
  description:
    "Created an app for an online dance school on Flutter with a course recording feature, improving user engagement and simplifying content interaction – EdTech",
  cost: "25 000 $",
  timeline: "5 months",
  teamSize: "7 person",
  techStack: "NodeJs, React, Flutter, PostgreSQL, Custom Integrations",
  about:
    "Jance is a dynamic platform that connects a large community of dancers, offering a variety of online courses led by professional choreographers. The platform provides a comprehensive library of video lessons, allowing users to learn and practice different dance styles at their own pace, regardless of location. The main goal of Jance is to create an immersive learning environment where users can not only access high-quality dance content but also interact with instructors, track their progress, and engage with a broader community of dancers. This makes it more than just a course platform – it's a thriving digital space for dancers of all skill levels to improve and share their passion for dance.",
  website: "jance.com",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements:
    "The client sought to develop a custom mobile application to replace their existing platform on GetCourse. A key priority during the migration was to ensure the seamless transfer of user data, including active accounts and individual progress across all ongoing courses. The new application needed to provide a more engaging and feature-rich environment tailored specifically to the needs of their growing community of dancers. Key requirements included: Data migration, Custom app development, and Enhanced user engagement.",
  solution: `
We developed a cross-platform educational application using Flutter, ensuring a smooth experience across both iOS and Android. The app offers users access to dance courses, internal currency for transactions, homework, and media content. To facilitate course management, we built a custom content management system (CMS), enabling administrators and instructors to create, edit, and manage courses directly within the app.

Additionally, we integrated and customized a video player for seamless lesson viewing, enhancing the overall user experience. To manage users and control content distribution, we implemented an administrative panel that allows the client to oversee platform operations efficiently.

To ensure a smooth transition from the previous platform, we integrated an external system's API, successfully migrating the client's existing user base and course progress data without any disruption. This solution provided the client with full control over the platform while offering an engaging and flexible environment for users.`,
  results: `
The client received a fully customized application with all the required features, now live on both the App Store and Play Market. Since its launch, the platform has attracted 4,000 active users, with the user base steadily growing each day. The solution not only met the client's immediate needs but also laid the foundation for future scalability and growth.`,
};
