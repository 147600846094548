import heroImg from "assets/images/consult-hero.png";
import Button from "../Button/Button";
import styles from "./HeroSection.module.css";

export default function HeroSection() {
  return (
    <section className={styles.hero}>
      <img src={heroImg} alt="Hero" className={styles.heroImage} />
      <div className={styles.content}>
        <h1 className={styles.title}>
          Get the technology advice you need to build and scale your startup
        </h1>
        <div className={styles.mobileImage}>
          <img src={heroImg} alt="Hero" />
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://calendly.com/vlad_k-cherrypick/30min "
        >
          <Button>Book a free 30-minute consultation</Button>
        </a>
      </div>
    </section>
  );
}
