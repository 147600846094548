import styles from "./FrameworkImagesAnimation.module.scss";

import { useEffect, useRef } from "react";

import Matter from "matter-js";

import reactIcon from "../../../assets/images/react-icon.png";
import cSharpIcon from "../../../assets/images/c-sharp-icon.png";
import flutterIcon from "../../../assets/images/flutter-icon.png";
import netIcon from "../../../assets/images/net-icon.png";
import nodeIcon from "../../../assets/images/node-js.png";
import pythonIcon from "../../../assets/images/python.png";
import angularIcon from "../../../assets/images/angular.png";
import vueIcon from "../../../assets/images/vue.png";
import dockerIcon from "../../../assets/images/docker.png";
import gitlabIcon from "../../../assets/images/gitlab.png";
import awsIcon from "../../../assets/images/AWS.png";
import gcpIcon from "../../../assets/images/GCP.png";
import kibanaIcon from "../../../assets/images/kibana.png";
import kubernetesIcon from "../../../assets/images/Kubernetes.png";
import seleniumIcon from "../../../assets/images/selenium.png";
import postmanIcon from "../../../assets/images/postman.png";
import jmetterIcon from "../../../assets/images/Jmeter.png";
import figmaIcon from "../../../assets/images/figma.png";
import abstractIcon from "../../../assets/images/abstract.png";
import sketchIcon from "../../../assets/images/sketch.png";
import appleIcon from "../../../assets/images/apple.png";
import androidIcon from "../../../assets/images/android.png";
import firebaseIcon from "../../../assets/images/firebase.png";
import goIcon from "../../../assets/images/go.png";

export const FrameworkImagesAnimation = ({ activeTab }) => {
  const refScene = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refScene) {
      const Engine = Matter.Engine,
        Render = Matter.Render,
        World = Matter.World,
        Bodies = Matter.Bodies,
        Mouse = Matter.Mouse,
        MouseConstraint = Matter.MouseConstraint;

      const engine = Engine.create({});

      let images: any = [];
      let things: any = [];

      switch (activeTab) {
        case "Flutter":
          images = [flutterIcon, appleIcon, androidIcon, firebaseIcon];
          break;
        case "Backend":
          images = [netIcon, cSharpIcon, nodeIcon, goIcon];
          break;
        case "Frontend":
          images = [angularIcon, vueIcon, reactIcon, flutterIcon];
          break;
        case "Devops":
          images = [dockerIcon, gitlabIcon, awsIcon, gcpIcon, kibanaIcon, kubernetesIcon];
          break;
        case "QA":
          images = [seleniumIcon, postmanIcon, jmetterIcon];
          break;
        case "Design":
          images = [figmaIcon, abstractIcon, sketchIcon];
          break;
        default:
          images = [netIcon, cSharpIcon, nodeIcon, goIcon];
          break;
      }

      console.log("refScene.current.offsetWidth", refScene?.current?.offsetWidth);

      const render = Render.create({
        element: document.querySelector("#content"),
        engine: engine,
        options: {
          //@ts-ignore
          width: refScene.current.offsetWidth,
          //@ts-ignore
          height: refScene.current.offsetHeight,
          wireframes: false,
          background: "none",
        },
      });
      //@ts-ignore
      const ground = Bodies.rectangle(
        //@ts-ignore
        refScene.current.offsetWidth / 2,
        //@ts-ignore
        refScene.current.offsetHeight + 40,
        //@ts-ignore
        refScene.current.offsetWidth,
        100,
        {
          render: { fillStyle: "transparent" },
          isStatic: true,
        }
      );
      const wallLeft = Bodies.rectangle(
        //@ts-ignore
        -40,
        //@ts-ignore
        refScene.current.offsetHeight / 2,
        100,
        //@ts-ignore
        refScene.current.offsetHeight,
        { render: { fillStyle: "transparent" }, isStatic: true }
      );
      const wallRight = Bodies.rectangle(
        //@ts-ignore
        refScene.current.offsetWidth + 40,
        //@ts-ignore
        refScene.current.offsetHeight / 2,
        100,
        //@ts-ignore
        refScene.current.offsetHeight,
        { render: { fillStyle: "transparent" }, isStatic: true }
      );
      const roof = Bodies.rectangle(
        //@ts-ignore
        refScene.current.offsetWidth / 2,
        //@ts-ignore
        -40,
        //@ts-ignore
        refScene.current.offsetWidth,
        100,
        { render: { fillStyle: "transparent" }, isStatic: true }
      );

      World.add(engine.world, [ground, wallLeft, wallRight, roof]);

      things = images.map((img) =>
        Bodies.circle(0, 50, 50, {
          render: {
            sprite: {
              texture: img,
              xScale: 0.4,
              yScale: 0.4,
            },
          },
        })
      );

      World.add(engine.world, things);

      const mouse = Mouse.create(render.canvas),
        mouseConstraint = MouseConstraint.create(engine, {
          mouse: mouse,
          constraint: {
            stiffness: 0.2,
            render: {
              visible: false,
            },
          },
        });

      mouseConstraint.mouse.element.removeEventListener(
        "mousewheel",
        mouseConstraint.mouse.mousewheel
      );
      mouseConstraint.mouse.element.removeEventListener(
        "DOMMouseScroll",
        mouseConstraint.mouse.mousewheel
      );

      let touchStart = null;
      mouseConstraint.mouse.element.addEventListener("touchstart", (event) => {
        if (!mouseConstraint.body) {
          touchStart = event;
        }
      });

      let space: any = null;

      mouseConstraint.mouse.element.addEventListener("touchend", (event) => {
        event.preventDefault();
        touchStart = null;
      });

      mouseConstraint.mouse.element.addEventListener("touchmove", (event) => {
        if (!mouseConstraint.body && touchStart) {
          event.preventDefault(); //@ts-ignore
          let start = touchStart.touches[0].clientY;
          let end = event.touches[0].clientY;
          let delta = start - end;
          window.scrollTo(0, window.scrollY + delta);
          touchStart = event;
        }
      });

      World.add(engine.world, mouseConstraint);

      Engine.run(engine);

      Render.run(render);
      return () => {
        World.clear(engine.world);
        Engine.clear(engine);
        Render.stop(render);
        render.canvas.remove();
        render.canvas = null;
        render.context = null;
      };
    }
  }, [refScene, activeTab]);

  return <div className={styles["images-container"]} ref={refScene} id={"content"} />;
};
