import screenshot_1 from "assets/images/cases/engagerocket/screenshot_1.png";
import screenshot_2 from "assets/images/cases/engagerocket/screenshot_2.png";
import screenshot_3 from "assets/images/cases/engagerocket/screenshot_3.png";

export const CASE_PAGES_ENGAGEROCKET = {
  name: "Engage Rocket",
  description:
    "Developed the second version of an HR platform by changing the architecture and implementing key features, which increased stability and opened new opportunities for working with large clients – HRTech",
  cost: "100 000 $+",
  timeline: "9 месяцев",
  teamSize: "30 человек",
  techStack: "Go, React, PostgreSQL, Kubernetes",
  about:
    "HR platform to attract and retain employees in a company. Engage Rocket is a large Singapore-based start-up that works with top brands around the world.",
  website: "engagerocket.co",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements: `The company has been operating since 2020 and during this time the application became outdated and could not cope with the large number of users.

During the development period, a large amount of technical debt of varying criticality had accumulated, which slowed down further product development and entry into new markets and larger customers.

Therefore, the client was looking for a contractor to create a new application.`,
  solution: `We analyzed the existing application and discussed the architecture and structure of the new application with the management.

In the new version, we needed to rebuild the application architecture to new standards and optimal approach. Plus, we took into account the requirements for security and further scaling of the project.

In addition, we implemented significant improvements to the application, which were developed together with the client taking into account technical, product and analytical aspects. This allowed us to find the most effective solutions.

We assembled a team of 30 people, developed a timeline to rewrite and improve the application in 7 months.`,
  results:
    "The client received a ready-made advanced application that is adapted to high loads. Now there are 350,000+ users on the platform and their number is growing every day.",
};
