import { memo } from "react";
import styles from "./NewProject.module.scss";
import dashImg from "../../assets/images/dash.svg";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ArrRight } from "assets/images/white-arr-right";
import SloganTyping from "../TypingEffect/SloganTyping";

const NewProjectLayout = () => {
  const navigate = useNavigate();
  const slogan = [
    { text: "var ", color: "#3037b7" },
    { text: "newTeam ", color: "#2d85d0" },
    { text: "= ", color: "#969696" },
    { text: "hire ", color: "#ceba34" },
    { text: "(", color: "#de711b" },
    { text: `'CherryPick'`, color: "#a22a0e" },
    { text: ")", color: "#de711b" },
    { text: ";", color: "#969696" },
  ];
  const comment = [
    { text: "/* ", color: "#c2c2c2" },
    { text: " button to hire your development team ", color: "#000000" },
    { text: "*/ ", color: "#c2c2c2" },
  ];
  return (
    <div className={styles["new-prj-layout"]}>
      <div></div>
      <div className={styles["new-prj-code-cnt"]}>
        {/*<img src={codeImg} alt="code-image" />*/}
        <div className={styles["new-prj-code-cnt__text"]}>
          <p>
            <SloganTyping slogan={slogan} />
          </p>
          <p>
            <SloganTyping slogan={comment} />
          </p>
        </div>
        <button onClick={() => navigate("/contact-us")}>
          Start a new project <ArrRight />
        </button>
      </div>
    </div>
  );
};

export default memo(NewProjectLayout);
