import screenshot_1 from "assets/images/cases/albione/screenshot_1.png";
import screenshot_2 from "assets/images/cases/albione/screenshot_2.png";
import screenshot_3 from "assets/images/cases/albione/screenshot_3.png";

export const CASE_PAGES_ALBIONE = {
  name: "Albione",
  description:
    "Redesigned a mobile app for a clothing e-commerce store by adding new features and improving the user interface, leading to an increase in the number of orders – E-com",
  cost: "+15 000$",
  timeline: "2 months",
  teamSize: "3 person",
  techStack: "Flutter",
  about:
    "Albione is a brand for strong men who appreciate comfortable clothes of the highest quality. The fit of the clothes is tailored to the individual physique of each customer.",
  website: "albione.com",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements: "Development of a mobile application for an existing online clothing store",
  solution:
    "Integration to the current backend and development of the application according to the design",
  results: `
1. Updated interface: The user interface of the application was redesigned taking into account modern trends and usability. Navigation has been improved and new visual elements have been added.

2. Performance Optimization: The app's loading speed has been improved, allowing users to find and browse products faster.

3. New Features: Added new features such as:
- Personal account with order tracking.
- Social media integration for quick login and sharing purchases.
- Notifications about discounts and promotions.

4. Improved payment process: Implemented several new payment methods including mobile payments and e-wallets for user convenience.

5. Analytics and Monitoring: Integrate analytics tools to track user behavior and understand which features are most in demand.`,
};
