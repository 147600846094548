import screenshot_1 from "assets/images/cases/autletmarket/screenshot_1.png";
import screenshot_2 from "assets/images/cases/autletmarket/screenshot_2.png";
import screenshot_3 from "assets/images/cases/autletmarket/screenshot_3.png";

export const CASE_PAGES_AUTLET = {
  name: "Autlet",
  description:
    "Modernized a mobile app for clothing purchases, improving navigation and adding new search tools, which increased usability and conversion – E-com",
  cost: "+20 000$",
  timeline: "3 months",
  teamSize: "7 people",
  techStack: "NodeJS, React, Flutter, PostgreSQL",
  about:
    "An online store at a wholesale warehouse. Autlet has been supplying clothing from Europe wholesale for 28 years. Usually, these are deliveries in large bags without a choice of models and sizes. That's why we launched this online store, where you can buy clothes individually and choose only the sizes you need.",
  website: "autlet.com",
  images: [screenshot_1, screenshot_2, screenshot_3],
  mainRequirements:
    "Refinement of web and mobile applications, as well as adding functionality to the administrative panel",
  solution:
    "Selected and connected a team of specialists with suitable experience and technology stack",
  results:
    "Successfully brought the project to production on all platforms, staying within budget and deadline",
};
